import React, { useState, useContext, useEffect } from "react";
import { context } from "../../store";
import { AsyncStorage } from "react-native";
import { useHistory, Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import jwt from "jsonwebtoken";
import { JWTSecretKey } from "../../../config/config.js";

export default (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();
    const [state, dispatch] = useContext(context);
    const [user, setUser] = useState(null);

    useEffect(() => {
        (async function checkJWT() {
            jwt.verify(
                await AsyncStorage.getItem("jwt"),
                JWTSecretKey,
                (err, decoded) => {
                    if (decoded?.user) {
                        setUser(decoded?.user);
                    }
                },
            );
        })();
    }, []);

    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Brand
                onClick={() => {
                    history.push("/dashboard");
                }}
            >
                FitKabDao
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            {user && (
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        {user?.userRole === "admin" && (
                            <NavDropdown title="Admin" id="basic-nav-dropdown">
                                <NavDropdown.Item
                                    onClick={() => {
                                        history.push("/create-user");
                                    }}
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                >
                                    Create A New User
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                >
                                    Remove A User
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}

                        <NavDropdown title="Mobile App" id="basic-nav-dropdown">
                            <NavDropdown.Item
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                            >
                                Create Post
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                            >
                                Send Push Notifications
                            </NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title="Tools" id="basic-nav-dropdown">
                            <NavDropdown.Item
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                            >
                                Auto-Fill Orders
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>

                    <Nav
                        className="justify-content-end"
                        style={{ width: "100%" }}
                    >
                        <NavDropdown
                            title={`Logged in as ${user.firstName} ${user.lastName}`}
                            id="basic-nav-dropdown"
                        >
                            <NavDropdown.Item
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                onClick={async () => {
                                    history.push("/edit-account");
                                }}
                            >
                                Edit Account
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                onClick={async () => {
                                    await AsyncStorage.removeItem("jwt");
                                    history.push("/");
                                }}
                            >
                                Logout
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            )}
        </Navbar>
    );
};
