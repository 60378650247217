import React, { useEffect, useReducer } from "react";
import { Platform } from "react-native";
import { initialState, reducer, context } from "./store";

// need to import bootstrap css here in order for reactstrap to work
import "bootstrap/dist/css/bootstrap.min.css";

import Mobile from "./mobile/App";
import Web from "./web/App";

export default function Main() {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        (async function fetchData() {
            dispatch({
                type: "initial app load",
                payload: {
                    platform: Platform.OS,
                    ipAddress: (
                        await (
                            await fetch("https://api.ipify.org?format=json")
                        ).json()
                    ).ip,
                },
            });
        })();
    }, []);

    return (
        <context.Provider value={[state, dispatch]}>
            {state.platform === "mobile" ? (
                <Mobile state={state} />
            ) : (
                <Web state={state} />
            )}
        </context.Provider>
    );
}
