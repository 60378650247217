import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

// pages
import LoginPage from "./LoginPage/Index";
import Dashboard from "./Dashboard/Index";
import CreateUser from "./CreateUser/Index";
import EditAccount from "./EditAccount/Index";

export default function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={LoginPage} />
                <Route path="/dashboard" exact component={Dashboard} />
                <Route path="/create-user" exact component={CreateUser} />
                <Route path="/edit-account" exact component={EditAccount} />
            </Switch>
        </BrowserRouter>
    );
}
