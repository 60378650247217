export const createUser = `
    mutation createUser(
        $firstName: String!
        $lastName: String!
        $userRole: String!
        $email: String!
        $password: String!
        $jwt: String!
    ) {
        createUser(
            firstName: $firstName
            lastName: $lastName
            userRole: $userRole
            email: $email
            password: $password
            jwt: $jwt
        )
    }
`;

export const editAccountInfo = `
    mutation editAccountInfo(
        $firstName: String!
        $lastName: String!
        $email: String!
        $password: String!
        $jwt: String!
    ) {
        editAccountInfo(
            firstName: $firstName
            lastName: $lastName
            email: $email
            password: $password
            jwt: $jwt
        )
    }
`;

export const editPassword = `
    mutation editPassword(
        $oldPassword: String!
        $newPassword: String!
        $jwt: String!
    ) {
        editPassword(
            oldPassword: $oldPassword
            newPassword: $newPassword
            jwt: $jwt
        )
    }
`;
