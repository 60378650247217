import React, { useContext, useEffect, useState } from "react";
import { View, ActivityIndicator, AsyncStorage } from "react-native";
import { context } from "../../store";
import { useHistory } from "react-router-dom";
import { apolloFetch } from "../../../lib/apollo-fetch";
import { editAccountInfo, editPassword } from "../../../graphql/mutations/main";
import jwt from "jsonwebtoken";
import { JWTSecretKey } from "../../../config/config.js";
import Header from "../common/Header";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";

export default function EditAccount() {
    const [state, dispatch] = useContext(context);
    const history = useHistory();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [infoPassword, setInfoPassword] = useState("");
    const [isLoadingInfo, setIsLoadingInfo] = useState(false);

    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isLoadingPassword, setIsLoadingPassword] = useState(false);

    useEffect(() => {
        (async function checkJWT() {
            jwt.verify(
                await AsyncStorage.getItem("jwt"),
                JWTSecretKey,
                (err, decoded) => {
                    if (err) {
                        history.push("/");
                        return;
                    }

                    setFirstName(decoded.user.firstName);
                    setLastName(decoded.user.lastName);
                    setEmail(decoded.user.email);
                },
            );
        })();
    }, []);

    return (
        <>
            <Header />

            <View
                style={{
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                    minWidth: "400px",
                }}
            >
                <Card>
                    <Card.Header as="h5">Edit your information</Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>First Name*</Form.Label>
                                <Form.Control
                                    type="firstName"
                                    placeholder="Dao"
                                    defaultValue={firstName}
                                    onChange={(event) => {
                                        setFirstName(event.target.value);
                                    }}
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Last Name*</Form.Label>
                                <Form.Control
                                    type="lastName"
                                    placeholder="Arthitaurai"
                                    defaultValue={lastName}
                                    onChange={(event) => {
                                        setLastName(event.target.value);
                                    }}
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email*</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="you@example.com"
                                    defaultValue={email}
                                    onChange={(event) => {
                                        setEmail(event.target.value);
                                    }}
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Password*</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="********"
                                    defaultValue={infoPassword}
                                    onChange={(event) => {
                                        setInfoPassword(event.target.value);
                                    }}
                                />
                            </Form.Group>

                            <Button
                                variant="primary"
                                type="submit"
                                style={{ minWidth: 75 }}
                                onClick={async (event) => {
                                    event.preventDefault();
                                    setIsLoadingInfo(true);

                                    if (!firstName.length) {
                                        setIsLoadingInfo(false);
                                        alert("First name cannot be empty.");
                                        return;
                                    }

                                    if (!lastName.length) {
                                        setIsLoadingInfo(false);
                                        alert("Last name cannot be empty.");
                                        return;
                                    }

                                    if (!email.length) {
                                        setIsLoadingInfo(false);
                                        alert("Email cannot be empty.");
                                        return;
                                    }

                                    if (
                                        !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
                                            email,
                                        )
                                    ) {
                                        setIsLoadingInfo(false);
                                        alert("Must be a valid email.");
                                        return;
                                    }

                                    if (!infoPassword.length) {
                                        setIsLoadingInfo(false);
                                        alert("Password cannot be blank.");
                                        return;
                                    }

                                    const response = await apolloFetch({
                                        query: editAccountInfo,
                                        variables: {
                                            firstName,
                                            lastName,
                                            email,
                                            password: infoPassword,
                                            jwt: await AsyncStorage.getItem(
                                                "jwt",
                                            ),
                                        },
                                    });

                                    setIsLoadingInfo(false);

                                    if (
                                        response.data?.editAccountInfo?.message
                                    ) {
                                        alert(
                                            response.data.editAccountInfo
                                                .message,
                                        );
                                        if (
                                            response.data?.editAccountInfo?.jwt
                                        ) {
                                            AsyncStorage.setItem(
                                                "jwt",
                                                response.data?.editAccountInfo
                                                    ?.jwt,
                                            );
                                        }
                                    } else {
                                        alert(
                                            "There was an error saving your information.",
                                        );
                                    }
                                }}
                            >
                                {!isLoadingInfo ? (
                                    "Submit"
                                ) : (
                                    <ActivityIndicator color="white" />
                                )}
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>

                <Card style={{ marginTop: 20 }}>
                    <Card.Header as="h5">Edit your password</Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Old Password*</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="********"
                                    defaultValue={oldPassword}
                                    onChange={(event) => {
                                        setOldPassword(event.target.value);
                                    }}
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>New Password*</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="********"
                                    defaultValue={password}
                                    onChange={(event) => {
                                        setPassword(event.target.value);
                                    }}
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Confirm New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="********"
                                    defaultValue={confirmPassword}
                                    onChange={(event) => {
                                        setConfirmPassword(event.target.value);
                                    }}
                                />
                            </Form.Group>

                            <Button
                                variant="primary"
                                type="submit"
                                style={{ minWidth: 75 }}
                                onClick={async (event) => {
                                    event.preventDefault();
                                    setIsLoadingPassword(true);

                                    if (!firstName.length) {
                                        setIsLoadingPassword(false);
                                        alert("First name cannot be empty.");
                                        return;
                                    }

                                    if (!lastName.length) {
                                        setIsLoadingPassword(false);
                                        alert("Last name cannot be empty.");
                                        return;
                                    }

                                    if (!email.length) {
                                        setIsLoadingPassword(false);
                                        alert("Email cannot be empty.");
                                        return;
                                    }

                                    if (
                                        !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
                                            email,
                                        )
                                    ) {
                                        setIsLoadingPassword(false);
                                        alert("Must be a valid email.");
                                        return;
                                    }

                                    if (!oldPassword.length) {
                                        setIsLoadingPassword(false);
                                        alert("Old password cannot be blank.");
                                        return;
                                    }

                                    if (password !== confirmPassword) {
                                        setIsLoadingPassword(false);
                                        alert("Passwords do not match!");
                                        return;
                                    }

                                    if (password.length < 8) {
                                        setIsLoadingPassword(false);
                                        alert(
                                            "Password is too short. Must contain 8+ characters.",
                                        );
                                        return;
                                    }

                                    if (!/[a-z]/.test(password)) {
                                        setIsLoadingPassword(false);
                                        alert(
                                            "Password must contain at least one lowercase character.",
                                        );
                                        return;
                                    }

                                    if (!/[A-Z]/.test(password)) {
                                        setIsLoadingPassword(false);
                                        alert(
                                            "Password must contain at least one uppercase character.",
                                        );
                                        return;
                                    }

                                    if (!/[0-9]/.test(password)) {
                                        setIsLoadingPassword(false);
                                        alert(
                                            "Password must contain at least one number.",
                                        );
                                        return;
                                    }

                                    if (password.length < 8) {
                                        setIsLoadingPassword(false);
                                        alert(
                                            "Password is too short. Must contain 8+ characters.",
                                        );
                                        return;
                                    }

                                    const response = await apolloFetch({
                                        query: editPassword,
                                        variables: {
                                            oldPassword,
                                            newPassword: password,
                                            jwt: await AsyncStorage.getItem(
                                                "jwt",
                                            ),
                                        },
                                    });

                                    setIsLoadingPassword(false);

                                    if (response.data?.editPassword.message) {
                                        alert(
                                            response.data?.editPassword.message,
                                        );
                                    } else {
                                        alert(
                                            "There was an error updating your password",
                                        );
                                    }
                                }}
                            >
                                {!isLoadingPassword ? (
                                    "Submit"
                                ) : (
                                    <ActivityIndicator color="white" />
                                )}
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </View>
        </>
    );
}
