import React, { useContext, useEffect, useState } from "react";
import { View, ActivityIndicator, AsyncStorage } from "react-native";
import { context } from "../../store";
import { useHistory } from "react-router-dom";
import { apolloFetch } from "../../../lib/apollo-fetch";
import { createUser } from "../../../graphql/mutations/main";
import jwt from "jsonwebtoken";
import { JWTSecretKey } from "../../../config/config.js";
import Header from "../common/Header";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";

export default function CreateUser() {
    const [state, dispatch] = useContext(context);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userRole, setUserRole] = useState("Staff");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    useEffect(() => {
        (async function checkJWT() {
            jwt.verify(
                await AsyncStorage.getItem("jwt"),
                JWTSecretKey,
                (err, decoded) => {
                    if (err || decoded.user.userRole !== "admin") {
                        history.push("/");
                        return;
                    }
                },
            );
        })();
    }, []);

    return (
        <>
            <Header />

            <View
                style={{
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                    minWidth: "400px",
                }}
            >
                <Card>
                    <Card.Header as="h5">Create A New User</Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>First Name*</Form.Label>
                                <Form.Control
                                    type="firstName"
                                    placeholder="Dao"
                                    defaultValue={firstName}
                                    onChange={(event) => {
                                        setFirstName(event.target.value);
                                    }}
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Last Name*</Form.Label>
                                <Form.Control
                                    type="lastName"
                                    placeholder="Arthitaurai"
                                    defaultValue={lastName}
                                    onChange={(event) => {
                                        setLastName(event.target.value);
                                    }}
                                />
                            </Form.Group>

                            <Form.Group controlId="exampleForm.SelectCustom">
                                <Form.Label>User Role*</Form.Label>
                                <Form.Control
                                    as="select"
                                    custom
                                    onChange={(event) => {
                                        setUserRole(event.target.value);
                                    }}
                                    defaultValue={userRole}
                                >
                                    <option>Staff</option>
                                    <option>Admin</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email*</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="you@example.com"
                                    defaultValue={email}
                                    onChange={(event) => {
                                        setEmail(event.target.value);
                                    }}
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Password*</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="********"
                                    defaultValue={password}
                                    onChange={(event) => {
                                        setPassword(event.target.value);
                                    }}
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="********"
                                    defaultValue={confirmPassword}
                                    onChange={(event) => {
                                        setConfirmPassword(event.target.value);
                                    }}
                                />
                            </Form.Group>

                            <Button
                                variant="primary"
                                type="submit"
                                style={{ minWidth: 75 }}
                                onClick={async (event) => {
                                    event.preventDefault();
                                    setIsLoading(true);

                                    if (!firstName.length) {
                                        setIsLoading(false);
                                        alert("First name cannot be empty.");
                                        return;
                                    }

                                    if (!lastName.length) {
                                        setIsLoading(false);
                                        alert("Last name cannot be empty.");
                                        return;
                                    }

                                    if (!userRole.length) {
                                        setIsLoading(false);
                                        alert("User role cannot be empty.");
                                        return;
                                    }

                                    if (!email.length) {
                                        setIsLoading(false);
                                        alert("Email cannot be empty.");
                                        return;
                                    }

                                    if (
                                        !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
                                            email,
                                        )
                                    ) {
                                        setIsLoading(false);
                                        alert("Must be a valid email.");
                                        return;
                                    }

                                    if (password !== confirmPassword) {
                                        setIsLoading(false);
                                        alert("Passwords do not match!");
                                        return;
                                    }

                                    if (password.length < 8) {
                                        setIsLoading(false);
                                        alert(
                                            "Password is too short. Must contain 8+ characters.",
                                        );
                                        return;
                                    }

                                    if (!/[a-z]/.test(password)) {
                                        setIsLoading(false);
                                        alert(
                                            "Password must contain at least one lowercase character.",
                                        );
                                        return;
                                    }

                                    if (!/[A-Z]/.test(password)) {
                                        setIsLoading(false);
                                        alert(
                                            "Password must contain at least one uppercase character.",
                                        );
                                        return;
                                    }

                                    if (!/[0-9]/.test(password)) {
                                        setIsLoading(false);
                                        alert(
                                            "Password must contain at least one number.",
                                        );
                                        return;
                                    }

                                    if (password.length < 8) {
                                        setIsLoading(false);
                                        alert(
                                            "Password is too short. Must contain 8+ characters.",
                                        );
                                        return;
                                    }

                                    const response = await apolloFetch({
                                        query: createUser,
                                        variables: {
                                            firstName,
                                            lastName,
                                            userRole: userRole.toLowerCase(),
                                            email,
                                            password,
                                            jwt: await AsyncStorage.getItem(
                                                "jwt",
                                            ),
                                        },
                                    });

                                    if (response.data?.createUser) {
                                        setIsLoading(false);
                                        alert(response.data?.createUser);
                                    }
                                }}
                            >
                                {!isLoading ? (
                                    "Submit"
                                ) : (
                                    <ActivityIndicator color="white" />
                                )}
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </View>
        </>
    );
}
