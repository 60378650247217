import React, { useContext, useEffect, useState } from "react";
import { View, ActivityIndicator, AsyncStorage } from "react-native";
import { context } from "../../store";
import { useHistory } from "react-router-dom";
import { apolloFetch } from "../../../lib/apollo-fetch";
import { loginUser } from "../../../graphql/queries/main";
import jwt from "jsonwebtoken";
import { JWTSecretKey } from "../../../config/config.js";
import Card from "react-bootstrap/Card";
import Header from "../common/Header";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

export default function LoginPage() {
    const [state, dispatch] = useContext(context);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        (async function checkJWT() {
            jwt.verify(
                await AsyncStorage.getItem("jwt"),
                JWTSecretKey,
                (err, decoded) => {
                    if (!err) history.push("/dashboard");
                },
            );
        })();
    }, []);

    return (
        <>
            <Header />
            <View
                style={{
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                    minWidth: "400px",
                }}
            >
                <Card>
                    <Card.Header as="h5">Login</Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="you@example.com"
                                    defaultValue={email}
                                    onChange={(event) => {
                                        setEmail(event.target.value);
                                    }}
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="********"
                                    defaultValue={password}
                                    onChange={(event) => {
                                        setPassword(event.target.value);
                                    }}
                                />
                                <Form.Text className="text-muted">
                                    Please reach out to a manager if you are
                                    unable to login.
                                </Form.Text>
                            </Form.Group>

                            <Button
                                variant="primary"
                                type="submit"
                                style={{ minWidth: 75 }}
                                onClick={async (event) => {
                                    event.preventDefault();
                                    setIsLoading(true);
                                    const response = await apolloFetch({
                                        query: loginUser,
                                        variables: {
                                            email,
                                            password,
                                        },
                                    });

                                    if (response.data?.loginUser?.jwt) {
                                        AsyncStorage.setItem(
                                            "jwt",
                                            response.data.loginUser.jwt,
                                        );
                                        history.push("/dashboard");
                                    } else {
                                        setIsLoading(false);
                                        alert(
                                            "Login failed. Please try again.",
                                        );
                                    }
                                }}
                            >
                                {!isLoading ? (
                                    "Submit"
                                ) : (
                                    <ActivityIndicator color="white" />
                                )}
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </View>
        </>
    );
}
