import React from "react";
import Main from "./src/components/Main";

import * as Sentry from "@sentry/browser";
Sentry.init({
    dsn:
        "https://aae0c55ad05b46e5b3c61b9d5f6b5882@o189240.ingest.sentry.io/1870051",
    sampleRate: 1.0,
    release: "bestremotejobs-backend@" + process.env.npm_package_version,
});

export default function App() {
    return <Main />;
}
