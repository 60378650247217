import React, { useContext, useEffect, useState } from "react";
import { Text, AsyncStorage } from "react-native";
import Header from "../common/Header";
import { context } from "../../store";
import { useHistory } from "react-router-dom";
import jwt from "jsonwebtoken";
import { JWTSecretKey } from "../../../config/config.js";

export default function LandingPage() {
    const [state, dispatch] = useContext(context);
    const history = useHistory();

    useEffect(() => {
        (async function checkJWT() {
            jwt.verify(
                await AsyncStorage.getItem("jwt"),
                JWTSecretKey,
                (err, decoded) => {
                    if (err) history.push("/");
                },
            );
        })();
    }, []);

    return (
        <>
            <Header />
            <Text style={{ margin: 10 }}>
                Please select an option from the navbar.
            </Text>
        </>
    );
}
