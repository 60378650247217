import { ENV } from "../config/config";
import { createApolloFetch } from "apollo-fetch";

let envURI = null;

if (ENV === "LOCAL") envURI = "http://localhost:8080/";

if (ENV === "STAGING")
    envURI = "https://admin-fitkabdao-staging.herokuapp.com/";

if (ENV === "PRODUCTION")
    envURI = "https://admin-fitkabdao-production.herokuapp.com/";

export const apolloFetch = createApolloFetch({
    uri: envURI,
});
